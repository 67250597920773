(function () {
  'use strict';

  class Settings {
    constructor(SettingsRest, $cookies, $rootScope) {
      this.SettingsRest = SettingsRest;
      this.$cookies = $cookies;
      this.$rootScope = $rootScope;
    }

    settingsIndex(settings = {}, callback = angular.noop) {
      return this.SettingsRest.settings().get(settings,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    settingsEdit(settings = {}, callback = angular.noop) {
      return this.SettingsRest.settings().update(settings,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }
  }

  /**
   * @ngdoc service
   * @name components.service:Settings
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('Settings', Settings);
}());
